@import "../../theme.less";

.footer {
  .footer-info {
    border-top: 2px solid @light-grey;
    padding-top: 35px;
    text-align: center;
  }

  .footer-links {
    color: rgba(0, 0, 0, 0.45);
    font-size: 28px;

    a {
      color: inherit;
      margin: 0px 20px;
    }

    a:hover,
    a:active,
    a:focus {
      color: @primary-color;
    }
  }

  .footer-copy {
    display: block;
    padding: 0px 10px;
    margin: 15px 0px 60px 0px;
  }
}

@primary-color: #08a060;