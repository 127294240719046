@import "../../theme.less";

.skills {
  .skills-card-row {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    grid-template-columns: repeat(2, 1fr);

    .for-size(laptop-up; {
      grid-template-columns: repeat(3, 1fr);
    });

    a {
      text-decoration: none;
      border-color: @light-grey;

      &:focus {
        border-color: @primary-color;
      }
    }

    .skills-card {
      border-color: inherit;
      font-family: inherit;
      padding: 20px 20px 0px 20px;

      img {
        max-height: 80px;
      }
    }

    .skills-card-meta {
      font-weight: 700;
      letter-spacing: 2px;
      text-align: center;
      text-transform: uppercase;

      .for-size(tablet-up; {
        font-size: 18px;
      });
    }
  }
}

@primary-color: #08a060;