@import "../../theme.less";

.date-location-entry {
  display: flex;
  flex-direction: row;

  span {
    display: inline-block;
  }

  .text {
    margin-right: 10px;

    .for-size(tablet-up; {flex: 0 0 auto;});
  }
}

@primary-color: #08a060;