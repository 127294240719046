@import "../../theme.less";

.contact {
  .contact-form {
    overflow: hidden;
    display: grid;
    row-gap: 8px;
    row-gap: auto;
    grid-template-columns: 1fr;

    .for-size(laptop-small-up; {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 32px;
      row-gap: 16px;
    });
  }

  .full-row {
    .for-size(laptop-small-up; {
      grid-column-start: 1;
      grid-column-end: 3;
    });
  }

  .contact-text-area {
    font-size: 16px;
  }

  .ant-form-item:not(.ant-form-item-has-error) {
    textarea:not(:focus),
    input:not(:focus) {
      border-color: @light-grey;
    }
  }
}

@primary-color: #08a060;