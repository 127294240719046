@import "../../theme.less";

.bio {
  margin-top: 40px;

  .bio-grid {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;

    & > :first-child {
      display: none;
    }

    .for-size(laptop-up; {
      & > :first-child {
        display: block
      }

      grid-template-columns: 1fr 5fr;
    });
  }

  .bio-text {
    .for-size(laptop-up; {padding-left: 80px;});
  }

  .bio-picture {
    img {
      background-color: white;
      border-radius: 100%;
      border: 4px solid @light-grey;
      height: 150px;
      object-fit: cover;
      object-position: 8px 10px;
      width: 150px;
    }
  }

  .bio-divider {
    background: @light-grey;
    display: none;
    height: 100%;
    position: absolute;
    width: 2px;

    .for-size(laptop-up; {display: block; margin-left: 35px;});
    .for-size(desktop-up; {display: block; margin-left: 20px;});
  }
}

@primary-color: #08a060;