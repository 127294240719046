@import "./theme.less";

/** Fonts **/
/* open-sans-regular - latin */
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("./assets/fonts/open-sans-v15-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/open-sans-v15-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("OpenSans-Italic"),
    url("./assets/fonts/open-sans-v15-latin-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/open-sans-v15-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("./assets/fonts/open-sans-v15-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/open-sans-v15-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBold Italic"), local("OpenSans-SemiBoldItalic"),
    url("./assets/fonts/open-sans-v15-latin-600italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/open-sans-v15-latin-600italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("./assets/fonts/open-sans-v15-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/open-sans-v15-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("./assets/fonts/open-sans-v15-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/open-sans-v15-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
    url("./assets/fonts/open-sans-v15-latin-700italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/open-sans-v15-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: local("Open Sans ExtraBold Italic"), local("OpenSans-ExtraBoldItalic"),
    url("./assets/fonts/open-sans-v15-latin-800italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/open-sans-v15-latin-800italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  background-color: @dirty-white;
  color: @grey;
  line-height: 1.5;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

#root {
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4 {
  border: 0;
  color: @main-black;
  letter-spacing: 1px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

h1 {
  font-size: 26px;
  line-height: normal;
  font-weight: 900;

  .for-size(laptop-small-up; {font-size: 34px;});
  .for-size(laptop-up; {font-size: 42px; line-height: 55px;});
}

h2 {
  font-size: 23px;
  font-weight: 700;
  line-height: 45px;

  .for-size(tablet-up; {font-size: 27px;});
  .for-size(laptop-up; {font-size: 32px;});
}

h3 {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;

  .for-size(tablet-up; {font-size: 20px;});
}

h4 {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;

  .for-size(tablet-up; {font-size: 16px;});
}

p {
  font-weight: 600;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

/** Overriding styles **/
.ant-btn {
  font-weight: 600;
  text-transform: uppercase;
}

.ant-card-hoverable:hover {
  -webkit-box-shadow: 0 5px 38px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 38px 2px rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.09);
}

.ant-dropdown {
  position: relative;
}

@primary-color: #08a060;