@import "../../theme.less";

.content {
  .content-title {
    text-align: center;

    .for-size(laptop-small-up; {text-align: initial});
  }

  .section-sub-title {
    color: @grey;
    display: block;
    font-weight: 500;
    margin-top: 2px;
    margin-bottom: 25px;

    .for-size(laptop-up; {margin-bottom: 35px;});
  }
}

@primary-color: #08a060;