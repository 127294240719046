@import "../../theme.less";

.projects {
  .projects-action-icon {
    font-size: 24px;
  }

  .projects-card-row {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    grid-template-columns: 1fr;

    .for-size(tablet-up; {
      grid-template-columns: repeat(2, 2fr);
    });
    .for-size(laptop-up; {
      grid-template-columns: repeat(3, 3fr);
    });
  }

  .projects-card {
    border-bottom: 4px solid @primary-color;
    cursor: auto;
    max-width: 100%;
    min-width: 0px;
    text-align: center;
    transition: all 0.3s;

    &:hover {
      border-bottom: 4px solid @primary-color;
      .for-size(laptop-small-up; {transform: translate(0px, -5px) ;});
    }

    a:hover,
    a:active,
    a:focus {
      color: @primary-color;
    }
  }

  .projects-card-subtitle {
    color: @grey;
    font-size: 13px;
    margin-top: 5px;
    white-space: nowrap;
  }
}

@primary-color: #08a060;