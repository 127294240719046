@import "../../theme.less";

.home {
  position: relative;

  .for-size(
    laptop-small-up; {background: url(./img/banner.png) no-repeat scroll 0 0 / cover;}
  );

  &::before {
    content: "";
    background-color: @main-blue;
    height: 100%;
    opacity: 0.85;
    position: absolute;
    width: 100%;
    z-index: 1;

    .for-size(laptop-small-up; {opacity: 0.7;});
  }

  p {
    line-height: 24px;
    letter-spacing: 0.1px;
    margin: 20px 0px 40px 0px;

    .for-size(desktop-up; {font-size: 16px; margin: 40px 0px;});
  }

  .home-content {
    background-color: #fff;
    border-top: 9px solid @primary-color;
    box-shadow: 0 2px 30px 5px rgba(51, 51, 51, 0.38);
    display: inline-grid;
    position: relative;
    vertical-align: bottom;
    z-index: 10;

    grid-template-columns: 1fr;
    margin-top: 110px;
    text-align: center;
    padding: 30px;
    .for-size(laptop-small-up; {
      padding: 50px 80px;
    });
    .for-size(laptop-up; {
      grid-template-columns: 1fr 2fr;
      text-align: left; 
      padding: 46px 70px 0 67px;
    });
    .for-size(desktop-up; {
      margin-top: 150px; 
      padding: 46px 90px 0 67px;
    });
  }

  .home-hero-wrapper {
    border-bottom: 1px solid @light-grey;
    .for-size(laptop-up; {border: none;});
  }

  #home-hero-image {
    height: 100%;
    width: 200px;

    .for-size(tablet-up; {width: 240px;});
    .for-size(desktop-up; {width: 300px;});
  }

  .home-sub-title {
    color: @primary-color;
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin: 8px 0px 0px 2px;
  }

  .home-description {
    padding-top: 20px;

    .for-size(laptop-up; {padding: 50px 20px 0px 40px;});
    .for-size(desktop-up; {padding: 50px 40px 0px 60px;});
  }

  .home-buttons {
    > * {
      &:not(:last-child) {
        margin-bottom: 25px;
      }

      .for-size(
        tablet-up; {margin-left: 13px; margin-right: 13px; margin-bottom: 0px;}
      );
      .for-size(laptop-up; {margin-left: 0px; margin-right: 25px;});
    }
  }
}

@primary-color: #08a060;