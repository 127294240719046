@import "../../theme.less";

.publications {
  .publications-entry {
    padding-bottom: @card-spacing;

    &:last-child {
      padding-bottom: 0px;
    }
  }
}

@primary-color: #08a060;